<template>
  <div class="content-tile">
    <div class="su-title">
      <i class="el-icon-document-copy"></i>
      <span>订单详细 </span>
    </div>
    <div>
      <!--采购端-->
      <template v-if="$store.state.currentPermissions==1">
        <template v-if="orderMessage.status_buyer==1">
          <template v-if="$store.state.manager==1">
            <el-button type="primary" @click="titleButtonClick(orderMessage.status_buyer)" v-if="orderMessage.status_buyer!=11">
              {{ orderMessage.status_buyer | filterStatusBuyer }}
            </el-button>
          </template>
        </template>
        <template v-else>
          <el-button type="primary" @click="titleButtonClick(orderMessage.status_buyer)" v-if="orderMessage.status_buyer!=11">
            {{ orderMessage.status_buyer | filterStatusBuyer }}
          </el-button>
        </template>

      </template>
      <!--销售端-->
      <template v-if="$store.state.currentPermissions==2">
        <el-button type="primary" @click="titleButtonClick(orderMessage.status_saleman)" v-if="orderMessage.status_saleman!=6">
          {{ orderMessage.status_saleman | filterStatusSaleman }}
        </el-button>
      </template>
      <!--成本中心-->
      <template v-if="$store.state.currentPermissions==3">
        <template v-if="$store.state.routerRole.indexOf('43')!=-1 || $store.state.routerRole.indexOf('1')!=-1">
          <el-button type="primary" @click="titleButtonClick(orderMessage.status_center)" v-if="orderMessage.status_center!=5">
            {{ orderMessage.status_center | filterStatusCenter }}
          </el-button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderMessage'],
  data() {
    return {};
  },
  filters: {
    filterStatusBuyer(state) {
      switch (state) {
        case 1:
          return '确认下单'
        case 2:
          return '确认预付申请'
        case 3:
          return '确认发预付款水单'
        case 4:
          return '确认备货'
        case 5:
          return '确认已备好货'
        case 6:
          return '确认已提交尾款申请'
        case 7:
          return '确认发尾款水单'
        case 8:
          return '确认下运单'
        case 9:
          return '确认已发货'
        case 10:
          return '确认已收货'
        case 11:
          return '已完成'
        case 12:
          return '确认下PO'
      }
    },
    filterStatusSaleman(state) {
      switch (state) {
        case 1:
          return '确认预付款到款'
        case 2:
          return '确认下请购单'
        case 3:
          return '确认入库'
        case 4:
          return '确认尾款到款'
        case 5:
          return '确认发货'
        case 6:
          return '已完成'
      }
    },
    filterStatusCenter(state) {
      switch (state) {
        case 1:
          return '确认预收款到账'
        case 2:
          return '核定单价'
        case 6:
          return '确认已付预付款'
        case 3:
          return '确认已付尾款'
        case 4:
          return '确认尾款到账'
        case 5:
          return '已完成'
      }
    }
  },
  mounted() {
  },
  methods: {
    titleButtonClick(status, aa) {
      this.$emit('titleButtonClick', status, aa)
    }
  },
};
</script>

<style lang="scss" scoped></style>
