<template>
  <div>
    <div class="content-main-title">
      <span>报价截图</span>
      <div class="hr"></div>
    </div>
    <ul>
      <li v-for="(item,key) in orderMessage.payment_screenshot" :key="key">
          <img :src="item">
      </li>
    </ul>
    <div class="content-main-title">
      <span>报价附件</span>
      <div class="hr"></div>
    </div>
    <ul>
      <li v-for="(item,key) in orderMessage.payment_attachment" :key="key">
        <span>{{item.name}}</span>
        <i @click="annexDownload(item.url)">下载</i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderMessage'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.location = url
    },
  },
};
</script>

<style lang="scss" scoped></style>
