<template>
  <div>
    <div class="content-main-title">
      <span>订单明细表</span>
      <div class="hr"></div>
    </div>
    <el-table
        ref="table"
        :data="orderMessage.order_spec"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
    >
      <template v-for="(item,key) in loopData">
        <el-table-column
            :key="key"
            v-if="item.competence.indexOf($store.state.currentPermissions)!=-1"
            min-width="140"
            :label="item.label"
            align="center"
        >
          <template slot-scope="scope">
            <el-input
                v-model="scope.row[item.field]"
                placeholder="请输入"
            />
          </template>
        </el-table-column>
      </template>
      <!--操作-->
      <el-table-column
          min-width="100px"
          align="center"
          label="操作"
      >

        <template slot-scope="scope">
          <el-button type="primary" @click="orderListEdit(scope.row)">
            修改
          </el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
  <!--order_spec: [{-->
</template>

<script>
import {orderDetailEdit} from '@/api/module/inquiryList'

export default {
  components: {},
  props: ['orderMessage', 'singleSelectionIds'],
  data() {
    return {
      loopData: [
        {
          label: '品牌（中文）',
          field: 'brand_ch',
          competence: [1, 2, 3]
        }, {
          label: '品牌（英文）',
          field: 'brand_en',
          competence: [1, 2, 3]
        }, {
          label: '品名/规格型号/单位',
          field: 'spec',
          competence: [1, 2, 3]
        }, {
          label: '数量',
          field: 'number',
          competence: [1, 2, 3]
        },
        // {
        //   label: '采购员',
        //   field: 'buyer_id',
        //   competence: [3]
        // },
        {
          label: '折后单价',
          field: 'purchase_money',
          competence: [1, 3]
        }, {
          label: '折扣',
          field: 'discount',
          competence: [1, 3]
        }, {
          label: '币种',
          field: 'currency',
          competence: [1, 3]
        }, {
          label: '总价',
          field: 'total_money',
          competence: [1, 3]
        }, {
          label: '单件重量',
          field: 'weight',
          competence: [1, 3]
        }, {
          label: '重量单位',
          field: 'weight_unit',
          competence: [1, 3]
        }, {
          label: '总重量',
          field: 'weight_total',
          competence: [1, 3]
        }, {
          label: '包装尺寸',
          field: 'pack_size',
          competence: [1, 3]
        }, {
          label: '包装单位',
          field: 'pack_unit',
          competence: [1, 3]
        }, {
          label: '货期',
          field: 'delivery_date',
          competence: [1, 2, 3]
        }, {
          label: '货期单位',
          field: 'delivery_date_unit',
          competence: [1, 2, 3]
        }, {
          label: '汇率',
          field: 'rate',
          competence: [3]
        }, {
          label: '换算单价（元）',
          field: 'change_money',
          competence: [3]
        }, {
          label: '国际运费',
          field: 'transport_money_intel',
          competence: [3]
        }, {
          label: '银行手续费',
          field: 'service_charge',
          competence: [3]
        }, {
          label: '关税',
          field: 'tax',
          competence: [3]
        }, {
          label: '增值税',
          field: 'add_tax',
          competence: [3]
        }, {
          label: '其他费用',
          field: 'other_money',
          competence: [3]
        }, {
          label: '国内运费',
          field: 'transport_money_ch',
          competence: [3]
        }, {
          label: '毛利',
          field: 'profit',
          competence: [3]
        }, {
          label: '销售单价',
          field: 'sale_money',
          competence: [2, 3]
        }, {
          label: '销售总价',
          field: 'sale_money_total',
          competence: [2, 3]
        },
      ]
    };
  },
  mounted() {
  },
  methods: {
    //修改
    orderListEdit(row) {
      this.$confirm('是否修改？',  {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = null;
        switch (this.$store.state.currentPermissions) {
          case 1://采购端
            data = {
              order_spec_id: row.order_spec_id,
              brand_ch: row.brand_ch, // 品牌中
              brand_en: row.brand_en, // 品牌英
              spec: row.spec, // 品名规格单位
              number: row.number, // 数量

              order_buyer_record_id: row.order_buyer_record_id,
              purchase_money: row.purchase_money, // 折后单价
              discount: row.discount, // 折扣
              currency: row.currency, // 币种
              total_money: row.total_money, // 总价
              weight: row.weight, // 单价重量
              weight_unit: row.weight_unit, // 重量单位
              weight_total: row.weight_total, // 总重量
              pack_size: row.pack_size, // 包装尺寸
              pack_unit: row.pack_unit, // 包装单位
              delivery_date: row.delivery_date, // 货期
              delivery_date_unit: row.delivery_date_unit, // 货期单位
            }
            break;
          case 2://销售端
            data = {
              order_spec_id: row.order_spec_id,
              brand_ch: row.brand_ch, // 品牌中
              brand_en: row.brand_en, // 品牌英
              spec: row.spec, // 品名规格单位
              number: row.number, // 数量
              sale_money: row.sale_money, // 销售单价
              sale_money_total: row.sale_money_total, // 销售总价

              order_buyer_record_id: row.order_buyer_record_id,
              delivery_date: row.delivery_date, // 货期
              delivery_date_unit: row.delivery_date_unit, // 货期单位
            }
            break
          case 3://成本中心
            data = {
              order_spec_id: row.order_spec_id,
              brand_ch: row.brand_ch, // 品牌中
              brand_en: row.brand_en, // 品牌英
              spec: row.spec, // 品名规格单位
              number: row.number, // 数量
              rate: row.rate, // 汇率
              change_money: row.change_money, // 换算单价
              transport_money_intel: row.transport_money_intel, // 国际运费
              service_charge: row.service_charge, // 银行手续费
              tax: row.tax, // 关税
              add_tax: row.add_tax, // 增值税
              other_money: row.other_money, // 其他费用
              transport_money_ch: row.transport_money_ch, // 国内运费
              profit: row.profit, // 毛利
              sale_money: row.sale_money, // 销售单价
              sale_money_total: row.sale_money_total, // 销售总价


              order_buyer_record_id: row.order_buyer_record_id,
              purchase_money: row.purchase_money, // 折后单价
              discount: row.discount, // 折扣
              currency: row.currency, // 币种
              total_money: row.total_money, // 总价
              weight: row.weight, // 单价重量
              weight_unit: row.weight_unit, // 重量单位
              weight_total: row.weight_total, // 总重量
              pack_size: row.pack_size, // 包装尺寸
              pack_unit: row.pack_unit, // 包装单位
              delivery_date: row.delivery_date, // 货期
              delivery_date_unit: row.delivery_date_unit, // 货期单位
            }
            break
        }
        orderDetailEdit(data).then(() => {
          this.$message.success('修改成功');
          this.$router.go(0);
        })

      }).catch(() => {
        this.$message.error('修改失败');
      });


    },
    //表格 第几行显示什么颜色
    tableRowClassName({row}) {
      if (this.singleSelectionIds.indexOf(row.id) != -1) {
        return 'warning-ids';
      } else {
        return 'warning-hide';
      }
      // console.log(row)
      /* if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },*/
    }
  }
};
</script>

<style lang="scss" scoped></style>
