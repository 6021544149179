<template>
  <div>
    <div class="content-main-title">
      <span>成单信息</span>
      <div class="hr"></div>
    </div>
    <ul class="order-message">
      <template v-for="(item,key) in loopData">
        <li :key="key" v-if="item.competence.indexOf($store.state.currentPermissions)!=-1">
          <span>{{ item.label }}</span>
          <i v-if="item.field!='finish_attachment'">{{ orderMessage[item.field] }}</i>
          <template v-else>
            <div v-for="(ite,key) in orderMessage[item.field]" :key="key"  class="download">
              {{ite.name}}
              <i @click="annexDownload(ite.url)">下载</i>
            </div>
          </template>
          <!---->
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderMessage'],
  data() {
    return {
      loopData: [
        {
          label: '合同金额',
          field: 'contract_money',
          competence: [2, 3]
        }, {
          label: '预付款比例',
          field: 'plan_pay_rate',
          competence: [1, 3]
        }, {
          label: '预收款金额',
          field: 'advance_money',
          competence: [3]
        }, {
          label: '预付款金额',
          field: 'plan_pay_money',
          competence: [1, 3]
        }, {
          label: '约定发货日期',
          field: 'deliver_date',
          competence: [1, 2]
        }, {
          label: '预收日期',
          field: 'advance_date',
          competence: [2, 3]
        }, {
          label: '付款尾款',
          field: 'plan_balance',
          competence: [1, 3]
        }, {
          label: '收款余款',
          field: 'balance',
          competence: [2, 3]
        }
       /* , {
          label: '待付款总额',
          field: 'contract_money_all',
          competence: [1, 3]
        }*/
        , {
          label: '描述',
          field: 'finish_desc',
          competence: [2, 3]
        }, {
          label: '附件',
          field: 'finish_attachment',
          competence: [2, 3]
        }
      ],
    };
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.location = url
    },
  },
};
</script>

<style lang="scss" scoped>
.order-message {
  overflow: hidden;
 padding: 0 12px;
  li {
    float: left;
    width: 33%;
    padding-bottom: 12px;
    @include flex();

    span {
      color: #7f8789;
      width: 80px;
      flex: 0 0 auto;
    }
  }
}
.download{
  @include flex();
  i{
    cursor: pointer;
    color: red;
    margin-left: 8px;
  }

}
</style>
