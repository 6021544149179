import { render, staticRenderFns } from "./OrderMessage.vue?vue&type=template&id=713aa185&scoped=true&"
import script from "./OrderMessage.vue?vue&type=script&lang=js&"
export * from "./OrderMessage.vue?vue&type=script&lang=js&"
import style0 from "./OrderMessage.vue?vue&type=style&index=0&id=713aa185&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713aa185",
  null
  
)

export default component.exports