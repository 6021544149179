import { render, staticRenderFns } from "./TitleButton.vue?vue&type=template&id=aa323466&scoped=true&"
import script from "./TitleButton.vue?vue&type=script&lang=js&"
export * from "./TitleButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa323466",
  null
  
)

export default component.exports