<template>
  <div>
    <div class="content-main-title">
      <span>订单信息</span>
      <div class="hr"></div>
    </div>
    <ul class="order-message">
      <template v-for="(item,key) in loopData">
        <li :key="key" v-if="item.competence.indexOf($store.state.currentPermissions)!=-1">
          <span>{{ item.label }}</span>
          <i v-if="item.status==1" class="color-red">
            <label v-if="item.field=='status_buyer'">{{orderMessage[item.field] | filterstatus_buyer}}</label>
            <label v-else-if="item.field=='status_saleman'">{{orderMessage[item.field] | filterstatus_saleman}}</label>
            <label v-else>{{orderMessage[item.field] | filterstatus_center}}</label>
          </i>
          <i v-else>{{ orderMessage[item.field]}}</i>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderMessage'],
  data() {
    return {
      loopData: [
        {
          label: '订单编号',
          field: 'seek_no',
          competence: [1, 2, 3]
        }, {
          label: '客户名称',
          field: 'customer_name',
          competence: [2, 3]
        }, {
          label: '订单状态',
          field: 'status_buyer',
          competence: [1],
          status:1,
        }, {
          label: '订单状态',
          field: 'status_center',
          competence: [3],
          status:1
        }, {
          label: '订单状态',
          field: 'status_saleman',
          competence: [2],
          status:1
        }, {
          label: '联系人',
          field: 'contacts',
          competence: [2]
        }, {
          label: '订单时间',
          field: 'order_record_date',
          competence: [1, 2, 3]
        }, {
          label: '职务',
          field: 'job',
          competence: [2]
        }, {
          label: '截止日期',
          field: 'end_time',
          competence: [2]
        }, {
          label: '手机号',
          field: 'phone',
          competence: [2]
        }, {
          label: '销售员',
          field: 'saleman_username',
          competence: [2, 3]
        }, {
          label: 'QQ/微信',
          field: 'wx',
          competence: [2]
        }, {
          label: '采购员',
          field: 'buyer_name',
          competence: [2, 3]
        }, {
          label: '邮箱',
          field: 'email',
          competence: [2]
        }, {
          label: '业务',
          field: 'center_username',
          competence: [2, 3]
        }
      ]
    };
  },
  filters: {
    filterstatus_buyer(state) {
      switch (state) {
        case 1:
          return '待下单'
        case 2:
          return '预付款待申请'
        case 3:
          return '待发预付款水单'
        case 4:
          return '待备货'
        case 5:
          return '待提前一周确认货是否备好'
        case 6:
          return '尾款待申请'
        case 7:
          return '待发尾款水单'
        case 8:
          return '待下运单'
        case 9:
          return '待发货'
        case 10:
          return '待收货'
        case 11:
          return '已完成'
        case 12:
          return '待确定下PO'
      }
    },
    filterstatus_saleman(state) {
      switch (state) {
        case 1:
          return '待预付款到款'
        case 2:
          return '待下单'
        case 3:
          return '待入库'
        case 4:
          return '尾款待到款'
        case 5:
          return '待发货'
        case 6:
          return '已完成'
      }
    },
    filterstatus_center(state) {
      switch (state) {
        case 1:
          return '预收款待到账'
        case 2:
          return '核定账单'
        case 3:
          return '待付尾款'
        case 4:
          return '余额待到账'
        case 5:
          return '已完成'
        case 6:
          return '待付预付款'
      }
    }
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.order-message {
  overflow: hidden;
  padding: 0 12px;
  li {
    float: left;
    width: 33%;
    padding-bottom: 12px;
    @include flex();

    span {
      color: #7f8789;
      width: 80px;
      flex: 0 0 auto;
    }
  }
}
</style>
