<template>
  <div>
    <div class="inquiry-list-box" v-loading="$store.state.loading">
      <el-scrollbar wrap-class="scrollbar-x">
        <div class="inquiry-list-box-left">
          <!--  <div class="search">
              <el-input
                  placeholder="请输入采购员名称"
                  suffix-icon="el-icon-search"
                  v-model="keyword">
              </el-input>
            </div>-->
          <div class="search">
            <el-input
                placeholder="请输入采购员名称"
                v-model="keyword"
            >
              <i slot="suffix" class="el-icon-search" @click="leftData(undefined)"></i>
            </el-input>
          </div>
          <div class="list">
            <ul>
              <li v-for="(item,key) in inquirylist"
                  :key="key"
                  :class="{'list-active':rightlistActive==key}"
                  @click="rightlistClick(key,item.id)"
              >
                <!--$store.state.currentPermissions!=1?item.id:item.order_id-->
                <dl>
                  <dt>
                    <div>
                      <i class="el-icon-caret-right"></i>
                      <span>{{ item.created_at }}</span>
                    </div>
                    <!-- <span
                         :class="{'n':item.seek_status==1 || item.seek_status==2,'n3':item.seek_status==3 || item.seek_status==4}"
                     >
                       {{ item.seek_status | filterState }}</span>-->
                  </dt>
                  <dd>询单号：{{ item.seek_no }}</dd>
                  <dd>品牌（中）:{{ item.brand_ch }}</dd>
                  <dd>品牌（英）:{{ item.brand_en }}</dd>
                  <dd>客户：{{ item.customer_name }}</dd>
                  <dd>销售员：{{ item.salemanName }}</dd>
                  <dd>采购：{{ item.order_buyer_for_onlyName }}</dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="leftCurPage">
            <el-pagination
                style="width: 50px"
                background
                layout=" pager"
                :page-size="30"
                @current-change="leftCurPageChange"
                :total="leftCurPage"
            >
            </el-pagination>
          </div>
        </div>
      </el-scrollbar>
      <!--right-->
      <el-scrollbar wrap-class="scrollbar-x" style="flex: 1">
        <div class="inquiry-list-box-right">
          <title-button :orderMessage="orderMessage" @titleButtonClick="titleButtonClick"/>
          <!--订单信息-->
          <order-message :orderMessage="orderMessage"/>
          <!--成单信息-->
          <form-single-message :orderMessage="orderMessage"/>
          <div class="content-main-title">
            <span>询单备注</span>
            <div class="hr"></div>
          </div>
          <div>{{ this.desc_saleman }}</div>
          <div class="content-main-title">
            <span>报价备注</span>
            <div class="hr"></div>
          </div>
          <div>{{ this.desc_buyer }}</div>
          <div class="content-main-title">
            <span>业务备注</span>
            <div class="hr"></div>
          </div>
          <div>{{ this.desc_center }}</div>

          <!--订单明细表-->
          <order-list :orderMessage="orderMessage" :singleSelectionIds="singleSelectionIds"/>
          <!--报价截图和附件-->
          <quote-screenshot :orderMessage="orderMessage"/>
          <!--订单跟踪记录-->
          <order-notice :orderMessage="orderMessage"/>
          <!--dialog-->
          <order-dialog :dialogData="dialogData" :dialogDataIsDialog="dialogDataIsDialog"
                        @titleOrderDialogSub="titleOrderDialogSub"/>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import TitleButton from "./components/TitleButton";
import OrderMessage from "./components/OrderMessage";
import FormSingleMessage from './components/FormSingleMessage';
import OrderList from './components/OrderList';
import QuoteScreenshot from './components/QuoteScreenshot'
import OrderNotice from './components/OrderNotice'
import OrderDialog from './components/OrderDialog'
import {leftNewOrder, orderDetail, orderDetailOperate} from "@/api/module/inquiryList";

export default {
  components: {OrderMessage, TitleButton, FormSingleMessage, OrderList, QuoteScreenshot, OrderNotice, OrderDialog},
  props: [],
  data() {
    return {
      leftCurPage:0,//let边总数
      leftCurrent_page:1,//left 左边第几页
      desc_saleman: '', //销售备注
      desc_buyer: '', //采购备注
      desc_center: '', //成本备注
      singleSelectionIds: '',//选择哪些订单
      keyword: '',
      //左边数据
      rightlistActive: 0,
      leftPage: 1,
      leftTotal: 1,
      inquirylist: [
        {
          created_at: '',//创建时间
          seek_status: 1,//询单状态
          seek_no: '',//询单号
          brand_ch: '',//品牌（中文）
          brand_en: '', //品牌（英文）
          customer_name: '',//客户名称
          buyer_id: ''//采购员
        }
      ],
      dialogDataIsDialog: {
        isDialog: false
      },
      dialogData: {
        operation: '',//操作
        status: '',//对应的询单状态(status_saleman,status_center,status_buyer)
        operate_date: new Date(),//记录日期
        desc: '',//描述
        attachment: [],//附件
        readble_salemane: true,// 销售可见   1-可见  2-隐藏
        readble_buyer: true,// 采购可见   1-可见  2-隐藏
        //确定下单才有已下字段
        plan_pay_rate: '',//预付款比例
        plan_pay_money: '',//预付款金额
        plan_balance: '',//尾款金额
      },
      //所有信息
      orderMessage: {
        //订单详细
        status_buyer: '',//采购状态
        status_center: '',//成本中心状态
        status_saleman: '',//销售员状态
        seek_no: '',//订单编号
        customer_name: '',//客户名称
        contacts: '',//联系人
        order_record_date: '',//订单时间
        job: '',//职务
        end_time: '',//截止日期
        phone: '',//手机号
        saleman_id: '',//销售员
        saleman_username: '',//销售员
        wx: '',//QQ/微信
        buyer_ids: '',//采购员id
        buyer_name: '',//采购员名称
        email: '',//邮箱
        center_id: '',//业务id
        center_username: '',//业务员名称

        //成单信息
        contract_money: '',//合同金额
        plan_pay_rate: '',//预付款比例
        advance_money: '',//预收款金额
        plan_pay_money: '',//预付款金额
        deliver_date: '',//约定发货日期
        advance_date: '',//预收日期
        plan_balance: '',//尾款金额
        balance: '',//余款
        // contract_money_all: '',//待付款总额 自己算
        finish_desc: '',//描述
        finish_attachment: '',//附件

        //订单明细列表
        order_spec: [{
          brand_ch: '',//品牌（中文）
          brand_en: '',//品牌（英文）
          spec: '',//品名规格型号
          number: '',//数量
          buyer_id: '',//采购员id
          purchase_money: '',//折后单价--采购单价
          discount: '',//折扣
          currency: '',//币种
          total_money: '',//总价
          weight: '',//单件重量
          weight_unit: '',//重量单位
          weight_total: '',//总重量
          pack_size: '',//包装尺寸
          pack_unit: '',//包装单位
          delivery_date: '',//货期
          delivery_date_unit: '',//货期单位
          rate: '',//汇率
          change_money: '',//换算单价（元）
          transport_money_intel: '',//国际运费
          service_charge: '',//银行手续费
          tax: '',//关税
          add_tax: '',//增值税
          other_money: '',//其他费用
          transport_money_ch: '',//国内运费
          profit: '',//毛利
          sale_money: '',//销售单价
          sale_money_total: '',//销售总价
        }],

        payment_screenshot: [],	//报价截图
        payment_screenshot_status: 1,	//报价截图-销售  1-可见 2-不可见
        payment_attachment: [],	//报价附件
        payment_attachment_status: 2,//报价附件 - 销售  1-可见  2-不可见

        //订单跟踪记录
        notice: [{
          operation: '',//操作
          operate_date: '',//记录日期
          operator_name: '',//操作人
          desc: '',//描述
          attachment: [],//附件
        }]
      }
    };
  },
  filters: {
    filterState(state) {
      switch (state) {
        case 1:
          return '待询单'
        case 2:
          return '待报价'
        case 3:
          return '已报价'
        case 4:
          return '已完成'
      }
    }
  },
  created() {
    this.leftData();
  },
  mounted() {
  },
  methods: {
    //left 分页
    leftCurPageChange(val){
      this.leftCurrent_page=val;
      this.leftData();
    },
    //操作按钮
    titleButtonClick(state) {
      console.log(state)
      this.dialogDataIsDialog.isDialog = true;
      this.dialogData = this.$options.data().dialogData;
      this.dialogData.status = state + 1;
      if (this.$store.state.currentPermissions == 1) {
        switch (state) {
          case 1:
            this.dialogData.operation = '确认下单';
            break
          case 2:
            this.dialogData.operation = '确认预付申请';
            break
          case 3:
            this.dialogData.operation = '确认发预付款水单'
            break
          case 4:
            this.dialogData.operation = '确认备货'
            break
          case 5:
            this.dialogData.operation = '确认已备好货'
            break
          case 6:
            this.dialogData.operation = '确认已提交尾款申请'
            break
          case 7:
            this.dialogData.operation = '确认发尾款水单'
            break
          case 8:
            this.dialogData.operation = '确认下运单'
            break
          case 9:
            this.dialogData.operation = '确认已发货'
            break
          case 10:
            this.dialogData.operation = '确认已收货'
            break
          case 11:
            this.dialogData.operation = '已完成'
            break
          case 12:
            this.dialogData.operation = '确定下PO'
            break
        }
      } else if (this.$store.state.currentPermissions == 2) {
        switch (state) {
          case 1:
            this.dialogData.operation = '确认预付款到款'
            break
          case 2:
            this.dialogData.operation = '确认下请购单'
            break
          case 3:
            this.dialogData.operation = '确认入库'
            break
          case 4:
            this.dialogData.operation = '确认尾款到款'
            break
          case 5:
            this.dialogData.operation = '确认发货'
            break
          case 6:
            this.dialogData.operation = '已完成'
            break
        }
      } else {
        switch (state) {
          case 1:
            this.dialogData.operation = '确认预收款到账'
            break
          case 2:
            this.dialogData.operation = '核定单价'
            break
          case 3:
            this.dialogData.operation = '确认已付尾款'
            break
          case 4:
            this.dialogData.operation = '确认尾款到账'
            break
          case 5:
            this.dialogData.operation = '已完成'
            break
          case 6:
            this.dialogData.operation = '确认已付预付款'
            break
        }
      }
    },
    //操作dialog 提交
    titleOrderDialogSub() {
      this.dialogData.operate_date = this.dialogData.operate_date / 1000;
      this.dialogData.readble_salemane = this.dialogData.readble_salemane ? 1 : 2;
      this.dialogData.readble_buyer = this.dialogData.readble_buyer ? 1 : 2;
      this.dialogData.attachment = JSON.stringify(this.dialogData.attachment);
      let data = this.dialogData;
      if (this.$store.state.currentPermissions == 3) {
        if (this.dialogData.status == 3) {
          data.status = 6;
        }
        if (this.dialogData.status == 7) {
          data.status = 3;
        }
      }
      if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
        if (this.dialogData.status == 2) {
          data.status = 12;
        }
      }
      if (this.$store.state.currentPermissions == 1) {
        if (this.dialogData.status == 13) {
          data.status = 2;
        }
      }
      data.order_id = this.orderMessage.id;
      this.dialogDataIsDialog.isDialog = false;
      orderDetailOperate(data).then(() => {
        console.log(123)
        this.$router.go(0)
      })
    },
    //点击左边的列表
    rightlistClick(key, id) {
      console.log(id)
      this.rightlistActive = key;
      this.rightData(id)
    },
    // 接口左边的数据
    leftData(id) {
      this.$store.state.loading = true;
      let params = {
        seek_status: '4',
        type: 1,
        id: id,
        username: this.keyword,
        page:this.leftCurrent_page
      };
      leftNewOrder(params).then((result) => {
        this.leftCurPage=result.data.total;
        this.inquirylist = result.data.data.map((item) => {
          let order_buyer = '';
          for (let i = 0; i < item.order_buyer.length; i++) {
            order_buyer += item.order_buyer[i].buyer_name + ','
          }
          return {
            created_at: this.Utils.timeDate(item.created_at),//创建时间
            seek_status: item.seek_status,//询单状态
            seek_no: item.seek_no,//询单号
            brand_ch: item.brand_ch,//品牌（中文）
            brand_en: item.brand_en, //品牌（英文）
            customer_name: item.customer_name,//客户名称
            buyer_id: order_buyer,//采购员
            id: item.id,
            order_id: item.order_id,
            salemanName: item.saleman.username,//销售名称
            order_buyer_for_onlyName: item.order_buyer_for_only[0].buyer_name//对应的采购员
          };
        });
        this.rightData(result.data.data[0].id);
        /*if (this.$store.state.currentPermissions == 1) {
          this.rightData(result.data.data[0].order_id);
        } else {
          this.rightData(result.data.data[0].id);
        }*/
      })
    },
    // 右边数据
    rightData(id) {
      this.$store.state.loading = true;

      let params = {
        order_id: id
      };
      orderDetail(params).then((result) => {
        console.log(result)
        this.orderMessage = result.data;
        this.singleSelectionIds = result.data.checked;//选择了那些订单
        this.orderMessage.order_record_date = this.Utils.timeDate(result.data.order_record_date);
        this.orderMessage.end_time = this.Utils.timeDate(result.data.end_time);
        this.orderMessage.deliver_date = this.Utils.timeDate(result.data.deliver_date);
        this.orderMessage.advance_date = this.Utils.timeDate(result.data.advance_date);
        this.orderMessage.buyer_name = result.data.order_buyer_for_only[0].buyer_name;//采购员名称
        this.desc_saleman = result.data.desc_saleman,
            this.desc_center = result.data.desc_center,
            this.orderMessage.payment_screenshot = result.data.order_buyer_for_only[0].payment_screenshot == null ? '' : result.data.order_buyer_for_only[0].payment_screenshot.split(',');	//报价截图
        this.orderMessage.payment_screenshot_status = result.data.order_buyer_for_only[0].payment_screenshot_status;	//报价截图-销售  1-可见 2-不可见
        this.desc_buyer = result.data.order_buyer_for_only[0].desc_buyer,
            this.orderMessage.payment_attachment = JSON.parse(result.data.order_buyer_for_only[0].payment_attachment);	//报价附件
        this.orderMessage.payment_attachment_status = result.data.order_buyer_for_only[0].payment_attachment_status;//报价附件 - 销售  1-可见  2-不可见

        // this.orderMessage.contract_money_all=result.data.contract_money_all;
        //成单信息附件
        this.orderMessage.finish_attachment = JSON.parse(result.data.finish_attachment);
        //订单跟踪记录
        for (let i = 0; i < this.orderMessage.notice.length; i++) {
          this.orderMessage.notice[i].operate_date = this.Utils.timeDate(this.orderMessage.notice[i].operate_date)
          this.orderMessage.notice[i].attachment = JSON.parse(this.orderMessage.notice[i].attachment)
        }
      })
    }
  },
};
</script>

<style lang="scss">
.leftCurPage {
  position: fixed;
  width: 216px;
  height: 28px;
  bottom: 15px;
  background: #fff;

  .el-pager {
    background: #fff;

    li {
      margin: 0 !important;
      margin-right: 5px !important;
      min-width: 20px !important;
      height: 22px !important;
      line-height: 22px !important;
    }
  }
}
</style>
