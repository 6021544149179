<template>
  <div>
    <el-dialog
        :append-to-body="true"
        title="操作"
        :visible.sync="dialogDataIsDialog.isDialog"
        width="450px"
    >
      <div>
        <ul class="order-dialog">
          <li>
            <span>操作</span>{{ dialogData.operation }}
          </li>
          <li>
            <span>记录日期</span>
            <el-date-picker
                v-model="dialogData.operate_date" placeholder="记录日期" value-format="timestamp"
                dateType="time">
            </el-date-picker>
          </li>
          <li v-if="dialogData.status==13 && $store.state.currentPermissions==1">
            <span>预付款比例</span>
            <el-input v-model="dialogData.plan_pay_rate" placeholder="请输入预付款比例"></el-input>
          </li>
          <li v-if="dialogData.status==13 && $store.state.currentPermissions==1">
            <span>预付款金额</span>
            <el-input v-model="dialogData.plan_pay_money" placeholder="请输入预付款金额"></el-input>
          </li>
          <li v-if="dialogData.status==13 && $store.state.currentPermissions==1">
            <span>尾款金额</span>
            <el-input v-model="dialogData.plan_balance" placeholder="请输入尾款金额"></el-input>
          </li>
          <li>
            <span>描述</span>
            <el-input v-model="dialogData.desc" placeholder="请输入描述"></el-input>
          </li>
          <li>
            <span>附件</span>
            <el-upload
                :action="annexAction"
                :show-file-list="false"
                list-type="text"
                :auto-upload="true"
                :before-upload="annexUpload"
                :on-error="annexError"
                :on-success="annexSuccess"
                :multiple="false"
                :limit="9"
                :on-exceed="annexExceed"
                name="file"
                :data="annexData"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-checkbox v-model="dialogData.readble_buyer" v-show="false">采购可见</el-checkbox>
            <el-checkbox v-model="dialogData.readble_salemane" v-show="false">销售可见</el-checkbox>
          </li>
        </ul>
        <div class="content-main-annex">
          <ul>
            <li v-for="(item,key) in dialogData.attachment" :key="key">
              <span>{{ item.name }}</span>
              <i @click="annexDel(key)" class="del">删除</i>
              <i @click="annexDownload(item.url)">下载</i>
            </li>
          </ul>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogDataIsDialog.isDialog = false">取 消</el-button>
        <el-button type="primary" @click="orderDialogSub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['dialogData', 'dialogDataIsDialog'],
  data() {
    return {
      //附件
      annexAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      annexData: {remarks: 1}, //上传添加的字段
      annexName: '', //上传文件成功后文件的名称
    };
  },
  mounted() {
  },
  methods: {
    //dialog 确定
    orderDialogSub(){
      this.$emit('titleOrderDialogSub')
    },
    //附件下载
    annexDownload(url) {

      window.open(url)
    },
    //附件 移除文件时的钩子
    annexDel(key) {
      this.$confirm('此操作将删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogData.attachment.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //附件 上传失败的钩子
    annexError() {
      this.$message.error('附件上传失败上传失败');
    },
    //附件 上传成功钩子
    annexSuccess(file) {
      let data = {
        name: this.annexName,
        url: file.data
      }
      this.dialogData.attachment.push(data)
    },
    //附件 上传文件之前的钩子
    annexUpload(file) {
      this.annexName=file.name
      console.log(file)
     /* this.annexName=file.name;//名字存起来，传给后台要用
      const isText = (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
      if (!isText) {
        this.$message.error('上传附件格式错误!');
      }
      return isText;*/
    },
    //附件 文件超出个数限制时的钩子
    annexExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-main-annex{
  ul{
    overflow: hidden;
    li{
      width: 45%;
      margin-bottom: 12px;
      span{
        width: 90px;
      }
    }
  }
}
.order-dialog {
  li:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  li {
    @include flex();
    margin-bottom: 12px;

    span {
      width: 90px;
      flex: 0 0 auto;
      color: #7f8789;
    }

    .el-date-editor {
      width: 100%;
    }
    .el-button{
      margin-right: 12px;
    }
  }
}
</style>
