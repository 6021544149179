<template>
  <div>
    <div class="content-main-title">
      <span>订单跟踪记录</span>
      <div class="hr"></div>
    </div>
    <el-table
        ref="table"
        :data="orderMessage.notice"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
    >
      <template v-for="(item,key) in loopData">
        <el-table-column
            :key="key"
            min-width="100"
            :label="item.label"
            align="center"
        >
          <template slot-scope="scope">
            <span  v-if="item.field!='attachment'">{{scope.row[item.field]}}</span>
            <template v-else>
              <div v-for="(ite,key) in scope.row[item.field]" :key="key"  class="download">
                {{ite.name}}
                <i @click="annexDownload(ite.url)">下载</i>
              </div>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderMessage'],
  data() {
    return {
      loopData: [
        {
          label: '操作',
          field: 'operation',
        },{
          label: '记录日期',
          field: 'operate_date',
        },{
          label: '操作人',
          field: 'operator_name',
        },{
          label: '描述',
          field: 'desc',
        },{
          label: '附件',
          field: 'attachment',
          annex:1
        }
      ]

    };
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.location = url
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.download{
  @include flex();
  i{
    cursor: pointer;
    color: red;
    margin-left: 8px;
  }

}
</style>
